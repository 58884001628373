import React from "react"
import { Button, Form } from "react-bootstrap";
import register_gif from "../../images/register_gif.gif"
import register_img from "../../images/register_img.png"
import RegisterWrapper from "./style";
import Footer from "../Footer";
import { useNavigate } from 'react-router-dom';

const RegisterForm = () => {
    const navigate = useNavigate();
    return (
        <>
            <RegisterWrapper>
                <div className="register_img">
                    <img src={register_img} alt="register_img"/>
                </div>
                <div className="register_container">
                    <h1 className="register_title">Register Now!</h1>
                    <div className="register_from_container">
                        <div className="register_gif_section">
                            <img src={register_gif} alt="register_gif" />
                        </div>
                        <div className="reg_form_fields_container">
                            <Form>
                                <div className="reg_form_fields_section">
                                    <div className="form_fields_wrapper">
                                        <Form.Group controlId="formBasicEmail" className="form_group">
                                            <Form.Label className="form_label">First Name</Form.Label>
                                            <Form.Control type="text" className="form_input_field" required />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword" className="form_group">
                                            <Form.Label className="form_label">Last Name</Form.Label>
                                            <Form.Control type="text" className="form_input_field" required />
                                        </Form.Group>
                                    </div>
                                    <div className="form_fields_wrapper">
                                        <Form.Group controlId="formBasicEmail" className="form_group">
                                            <Form.Label className="form_label">E-Mail</Form.Label>
                                            <Form.Control type="email" className="form_input_field" required />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword" className="form_group">
                                            <Form.Label className="form_label">Phone</Form.Label>
                                            <Form.Control type="number" className="form_input_field" required />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="reg_submit_btn">
                                    <Button className="cancel_btn" variant="primary" onClick={()=>{navigate('/'); window.scrollTo(0, 0);}}>
                                        Cancel 
                                    </Button>
                                    <Button className="submit_btn" variant="primary" type="submit">
                                        Register
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </RegisterWrapper>
            <Footer/>
        </>
    )
}

export default RegisterForm;