import React from "react";
import { Button, Form } from "react-bootstrap";
import register_gif from "../../images/register_gif.gif";
import contact_us_img from "../../images/contact_us_img.png";
import mail_icon from "../../images/mail_icon.svg";
import location_contact from "../../images/location_contact.svg";
import ContactUsWrapper from "./style";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();
  return (
    <>
      <ContactUsWrapper>
       <div className="header_image">
            <img src={contact_us_img}/>
       </div>
       <div className="register_container">
                    <h1 className="register_title">Contact Us</h1>
                    <div className="register_from_container">
                        <div className="register_section">
                            <div className="inside_register">
                                <p className="contact_info_head">Contact Information</p>
                                <p className="contact_text">Fill up the form and our Team will get <br/>back to you within 24 hours.</p>
                               <div className="mail_row">
                                    <div className="circle_div"><img src={mail_icon}/></div>
                                    <p className="mail_text">fieldsale@gmail.com</p>
                               </div>

                               <div className="mail_row">
                                    <div className="circle_div"><img src={location_contact}/></div>
                                    <p className="mail_text">fieldsale@gmail.com</p>
                               </div>
                            </div>    
                            <div className="outer_circle margin_class"></div>
                            <div className="circle margin_class"></div>
                          
                        </div>

                        <div className="reg_form_fields_container">
                            <Form>
                                <div className="reg_form_fields_section">
                                    <div className="form_fields_wrapper">
                                        <Form.Group controlId="formBasicEmail" className="form_group">
                                            <Form.Label className="form_label">First Name</Form.Label>
                                            <Form.Control type="text" className="form_input_field" required />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword" className="form_group">
                                            <Form.Label className="form_label">Last Name</Form.Label>
                                            <Form.Control type="text" className="form_input_field" required />
                                        </Form.Group>
                                    </div>
                                    <div className="form_fields_wrapper">
                                        <Form.Group controlId="formBasicEmail" className="form_group">
                                            <Form.Label className="form_label">E-Mail</Form.Label>
                                            <Form.Control type="email" className="form_input_field" required />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword" className="form_group">
                                            <Form.Label className="form_label">Phone</Form.Label>
                                            <Form.Control type="number" className="form_input_field" required />
                                        </Form.Group>
                                    </div>
                                    <div className="form_fields_wrapper">
                                        <Form.Group controlId="formBasicEmail" className="form_group form_group_full">
                                            <Form.Label className="form_label">Message</Form.Label>
                                            <Form.Control type="text" placeholder="Write your message..." className="form_input_field placeholder_styles" required />
                                        </Form.Group>
                                       
                                    </div>
                                </div>
                                <div className="reg_submit_btn">                                  
                                    <Button className="submit_btn" variant="primary" type="submit">
                                        Send Message
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
      </ContactUsWrapper>
      <Footer />
    </>
  );
};

export default ContactUs;
