import React from "react"
import FieldSale from "../../images/FieldSale.svg"
import FieldSale_white from "../../images/FieldSale_white.svg"
import Logo1 from "../../images/FieldSale_white.svg"
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import FooterWrapper from "./style";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    return (
        <>
            <FooterWrapper>
                <div className="footer_container">
                    <div className="footer_logo">
                        <img src={FieldSale} alt="FieldSale" />
                        <img src={Logo1} alt="Logo" />
                    </div>
                    <div className="footer_lists_section">                    
                        <ul className="footer_lists">
                            <li onClick={()=>{navigate('/'); window.scrollTo(0, 0);}} className="footer_menus">Home</li>
                            <li onClick={()=>{navigate('/contactus'); window.scrollTo(0, 0);}} className="footer_menus">Contact us</li>
                            <li onClick={()=>{navigate('/register'); window.scrollTo(0, 0);}} className="footer_menus">Register</li>
                        </ul>
                        <div className="social_media_section">
                            <div className="social_media_icon_box"><AiFillInstagram className="social_media_icoc"/></div>
                            <div className="social_media_icon_box"><FaFacebookF /></div>
                            <div className="social_media_icon_box"><FaLinkedinIn /></div>
                            <div className="social_media_icon_box"><FaTwitter /></div>    
                        </div>
                    </div>
                    <div className="footer_copyrights_Section">
                        <ul className="footer_copyrights_lists">
                            <li>Copyright 2023</li>
                            <li>Terms & Conditions</li>
                            <li>Privacy</li>
                        </ul>
                    </div>
                </div>
            </FooterWrapper>
        </>
    )
}

export default Footer;