import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from "./components/Home"
import RegisterForm from './components/RegisterForm';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <div className="App">
    
     <Routes>
        <Route path="/" exact element={ <Home/>} />
        <Route path="/register" exact element={<RegisterForm />} />
        <Route path="/contactus" exact element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
