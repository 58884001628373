import React from "react";
import { HomeView } from "./style";
import Logo from "../../images/FieldSale.svg";
import Logo1 from "../../images/Logo1.svg";
import right_arrow from "../../images/right_arrow.png";
import master_data from "../../images/master_data.png";
import role_img from "../../images/role_img.png";
import call from "../../images/call.gif";
import dashboardLogo from "../../images/dashboard.svg";
import trackLogo from "../../images/track.svg";
import taskLogo from "../../images/tasks.svg";
import orderLogo from "../../images/orders.svg";
import expensesLogo from "../../images/expenses.svg";
import customersLogo from "../../images/customers.svg";
import down_arrow from "../../images/down_arrow.svg";
import Software_testing from "../../images/Software_testing.svg";
import Business_deal from "../../images/Business_deal.svg";
import team_checklist from "../../images/team_checklist.svg";
import Location_tracking from "../../images/Location_tracking.svg";
import Data_report from "../../images/Data_report.svg";
import dotted_2 from "../../images/dotted_line_1.svg";
import dotted_1 from "../../images/dotted_line_2.svg";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import contact_us_gif from "../../images/contact_us_gif.gif";

export const Home = () => {
    const data = [
        {
            name:"Dashboard",
            content:"Shows an overall percentage and the exact number of orders processed, fuel spent, travel & other expenses.",
            icon: dashboardLogo
        },
        {
            name:"Track",
            content:"Our Advanced GPS Tracking Ensures Real-Time Visibility of Your Package Carriers in this section.",
            icon: trackLogo
        },
        {
            name:"Tasks",
            content:"Each task created by the admin is displayed in a neat format along with the priority level assigned to it. Only admin can create tasks.",
            icon: taskLogo
        },
        {
            name:"Orders",
            content:"Our User-Friendly Interface Displays a Clear List of Placed Orders by customers, Complete with Placed Dates and Due Dates along with the amount.",
            icon: orderLogo
        },
        {
            name:"Expenses",
            content:"One can Easily Monitor the Expenditures with a Detailed Overview of Date, Expense Types, Time Records, and Amounts Spent by an employee.",
            icon: expensesLogo
        },
        {
            name:"Customers",
            content:"This section has all the customers with a unique code, email and activity status to modulate the flow.",
            icon: customersLogo
        },
    ]
  return (
    <>
        <HomeView>
        <nav className="navbar px-3">
            <div className="logo">
            <img src={Logo} />
            <img src={Logo1} />
            </div>
            <div className="nav-links ">
           
                <Link to="/register" className="register-btn">
                    Register <img src={right_arrow} />
                </Link>
                <Link to="/contactus" className="contact-btn">
                    Contact Us <img src={call} height={30} />
                </Link>
            </div>
        </nav>
        <div className="content_1">
            <div className="home_contents">
            <p className="home_contents_header">
                Delivering Dreams,
                <br />
                One Sales Solution at a Time
            </p>
            <p className="home_contents_sub_text">
                Welcome to our Sales Solution website, where we bring you the tools
                and strategies you need to elevate your sales game and drive
                business
                growth. Whether you're a seasoned sales professional or just
                starting 
                your journey, our platform is designed to empower you with the
                resources to succeed in today's dynamic marketplace.
            </p>
            <p className="more_btn_container"><button className="more_btn">Find Out More</button></p>
            </div>
        </div>
       
            <div className="features">
                <p className="offer">Features we offer:</p>
                <p className="feature_content">Explore Our Comprehensive Range of Services <br/>and Benefits.</p>
            </div>
            <div className="feature_container">
                {
                    data.map((item, index)=>{
                        return(
                            <div key = {index} className="feature_card">
                                <p className="feature_icon"><img src = {item.icon}/></p>
                                <p className="feature_name">{item.name}</p>
                                <p className="feature_content_1">{item.content}</p>
                            </div>
                        )
                    })
                }
            </div>
            <hr className="feature_hr"/>
            <div className="admin_container">
                <p className="admin_header">Powers of an ADMIN</p>
                <div className="master_container">
                    <div className="master_data">
                        <img src={down_arrow}/>
                        <ol className="master_data_ol">
                            <li>Master Data</li>
                            <li>Users</li>
                            <li>Roles and Permissions</li>
                        </ol>
                    </div>
                    <div className="master_data_img">
                        <img src={master_data}/>
                    </div>

                </div>
                <div className="roles">
                    <p className="roles_header">ROLES AND PERMISSIONS</p>  
                    <div className="roles_data_img">
                        <img src={role_img}/>
                    </div>  
                    <div className="roles_data"> 
                        <p><span className="roles_data_bold">ROLES AND PERMISSIONS </span>have contain 3 sub sections- <br/>Role Master, Role Actions and Role Assignment.</p>                
                        <p><span className="roles_data_bold">Role Master</span > states the different types of roles present.</p>
                        <p><span className="roles_data_bold">Role Actions</span > states the different types of actions a Role Maser can do It also has a filter option to choose <br/>the particular  Role Master.</p>
                        <p><span className="roles_data_bold">Role Assignment showcases the different types of roles assigned to different employees. Only the <br/>Admin can see this section.</span ></p>
                    </div>
                </div>
                
            </div>
            <div className="roles_circle">
            </div>
            <div className="works_container">
                <div className="roles_circle_right"></div>                   
                <div>
                    <p className="how_it_works">
                        How it works
                    </p>
                </div>
                <div className="working_container_1">
                    <div className="working_content_box">
                        <img className="data_report_img" src={Software_testing}/>
                        <div className="working_content">
                            <p className="working_content_head">Create your fieldsale account</p>
                            <p className="working_content_text">Create your FieldSale account by registering for your<br/> company from our website.</p>
                        </div>
                    </div>
                    <img className="dotted_lines" src={dotted_1}/>

                    <div className="working_content_box">                       
                        <div className="working_content">
                            <p className="working_content_head">Add your team member</p>
                            <p className="working_content_text">Add your team member by giving their email id and <br/>manage your team efficiently.</p>
                        </div>
                        <img className="data_report_img" src={Business_deal}/>
                    </div>
                    <img className="dotted_lines" src={dotted_2}/>

                    <div className="working_content_box">
                        <img className="data_report_img" src={team_checklist}/>
                        <div className="working_content">
                            <p className="working_content_head">Assign trip to your team</p>
                            <p className="working_content_text">You can create and assign trip to you team member <br/>using FieldSale software.</p>
                        </div>
                    </div>
                    <img className="dotted_lines" src={dotted_1}/>

                    <div className="working_content_box">                       
                        <div className="working_content">
                            <p className="working_content_head">Track and manage the progress of your trip</p>
                            <p className="working_content_text">Track progress of all your team members and <br/>manage your time and resource with FieldSale.</p>
                        </div>
                        <img className="data_report_img" src={Location_tracking}/>
                    </div>
                    <img className="dotted_lines" src={dotted_2}/>

                    <div className="working_content_box">    
                        <img className="data_report_img" src={Data_report}/>                       
                        <div className="working_content">
                            <p className="working_content_head">Get reports on your trip expenses and trip timing.</p>
                            <p className="working_content_text">You can also use FieldSale to analyze your team and <br/>the expense of all your trip.</p>
                        </div>
                       
                    </div>
                   
                </div>
            </div>
            <div className="contact_us_container">
                <div className="contact_us_bottom">
                    <p>Contact Us</p>
                    <img src={contact_us_gif}/>
                </div>
                <p className="contact_us_text">Get in touch with us right away!</p>
                <div className="next_step_container">
                    <div className="next_step_col">
                        <p className="next_step_head">Let’s take the next step!</p>
                        <p className="next_step_text">Subscribe to receive the latest news and updates about FieldSale.<br/>
                            We promise not to spam you!
                        </p>
                    </div>
                    <div className='inputWithButton_col'>
                        <form>
                            <div className='inputWithButton'>
                                <input className="next_step_input" type="email" placeholder="Enter email address" required/>
                                <button type="submit" className="next_step_btn">Continue</button>
                            </div>                            
                        </form>                        
                    </div>
                </div>
            </div>
           
            
        
        </HomeView>
        <Footer />
    </>
  );
};
export default Home;
