import styled from "styled-components";

const RegisterWrapper = styled.div`
.register_img img{
    width: 100%;
    object-fit: contain;
}
.register_container{
    width: 100%;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.register_title{
    margin: 0;
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;
    padding-bottom: 30px;
}
.register_from_container{
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 80px;
    border: 1px solid var(--greyish-blue-tint, #535674);
    background: #FFF;
    padding: 2rem;
}
.register_gif_section{
    width: 500px;
    height: 450px;
    flex-shrink: 0;
}
.form_group{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    margin-bottom: 5rem;
}
.reg_form_fields_container{
    margin-top: 2.5rem;
    width: 100%;
}
.reg_form_fields_section{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.form_fields_wrapper{
    width: 100%;
    display: flex;
    gap: 3rem;
}
.form_label{
    color: #000;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    letter-spacing: 0.36px;
}
.form_input_field{
    width: 100%;
    height: 30px;
    border: 0px;
    outline: none;
    border-bottom: 1px solid rgba(83, 86, 116, 0.50);
}
.reg_submit_btn{
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    .submit_btn{
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        padding: 10px 20px;
        border: none;
        outline: none;
        border-radius: 30px;
        background: rgba(43, 50, 178, 0.80);
    }
    .cancel_btn{
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        border: none;
        outline: none;
        background: transparent;
    }
}
@media (min-width: 912px) and (max-width: 1168px){
    .form_fields_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .form_group{
        width: 100%;
        margin-bottom: 1rem;
        gap: 0.8rem;
    }
}
@media (min-width: 592px) and (max-width: 911px){
    .register_from_container{
        align-items: center;
        flex-direction: column;
        border-radius: 20px;
        padding: 1.4rem;
    }
}
@media (min-width: 320px) and (max-width: 591px){
    .register_from_container{
        width: 90%;
        align-items: center;
        flex-direction: column;
        border-radius: 16px;
        padding: 1rem;
    }
    .register_gif_section{
        width: 100%;
        height: 400px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .form_fields_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .form_group{
        width: 100%;
        margin-bottom: 1rem;
        gap: 0.8rem;
    }
}
`
export default RegisterWrapper;