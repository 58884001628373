import Styled from "styled-components";
import handShakeImg from "../../images/hand_shake.jpeg"

export const HomeView = Styled.div`   
  .navbar {
    background-color: #ffffff;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }  
  .logo {
    display: flex;
    flex-direction: column;
}  
  .nav-links a {
    text-decoration: none;
    color: white;
    margin-left: 20px;    
  }
  .nav-links{
    display: flex;
    flex-wrap: nowrap;
  }
  
  .register-btn {
    img{
        height: 15px;
    }
    display: flex;
    align-items:center;
    background-color: #4680ff;
    padding: 10px 15px;
    border-radius: 20px;
  }
  
  .contact-btn {
    background-color: #ffffff;
    padding: 0px 5px;
    display: flex;
    color: #4680ff!important;
    border-radius: 5px;
    border: 1px solid #4680ff;
    font-weight: bold;
    align-items: center;
}
.content_1 {
    background-image: url(${handShakeImg});
    background-size: cover;
    min-height: calc(100vh - 75px);
    width: 100%;
    background-position: center;
    object-fit: cover;
}
.home_contents{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #00000052;
}
.home_contents_header{
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 92% */
    letter-spacing: 3.5px;
    margin:0;
}
.home_contents_sub_text {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin:0;
    color: #FFF;
    text-align: justify;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
}
.more_btn_container{
    width: 45%;
    display: flex;
    margin-left:15px;
}
.more_btn{
    border-radius: 40px;
    padding: 10px;
    background: #000;
    color: #ffffff;
    border: none;
    width: 150px;
    font-weight: bold;
}

.offer{
    color: var(--general-dark, #222);
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}
.feature_content{
    color: var(--general-dark, #222);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.feature_container{    
    width:50%;
    display: grid;
    grid-template-columns: auto auto auto;  
    column-gap:35px;  
    row-gap:15px;  
    margin:auto;
}
.feature_card{
    padding:5px;
    width:100%;
    background:red;
    margin:5px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 10px 40px 0px rgba(34, 34, 34, 0.05);
}
.feature_icon{
    text-align:left;
    padding-left:15px;
}
.feature_name{
    color: var(--website-color, #4680FF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    text-align:left;
    line-height: 38px;
    padding-left:15px;
}
.feature_content_1{
    color: var(--general-dark, #222);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    text-align:left;
    font-weight: 400;
    line-height: 20px;
    padding-left:15px;
    margin-top:0;
}
.feature_hr{
    width:70%;
    stroke-width: 0.5px;
    stroke: #000000B2;
    opacity: 0.5;
    margin-top: 20px;
    margin-bottom: 75px;
}
.admin_container{
    width: 90%;
    min-height:calc(100vh - 100px);
    background: var(--website-color, #4680FF);
    margin: auto;
    padding: 20px;
}
.admin_header{
    color: #FFF;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 87.8%;
    letter-spacing: 2.1px;
}
.master_container{
    display: flex;
    margin-bottom:50px;
}
.master_data{
    width: 45%;
    display: flex;
    position: relative;
    left: 4%;
}
.master_data_img{
    border-radius: 14px;
    border: 10px solid #FFF;
   img{
    width: 100%;
    height: 285px;
    object-fit: contain;
   }
}
.master_data_ol{
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    text-align:left;
    font-weight: 400;
    line-height: 200%;
    letter-spacing: 1.68px;
}
.roles{
    padding-top:50px;
}
.roles_header{
    text-align:left;
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 200%; 
    letter-spacing: 1.68px;
    text-decoration-line: underline;
}
.roles_data_img{
    border-radius: 14px;
    width: 55%;
    border: 10px solid #FFF;
    margin: auto;
    img{
        width: 100%;
        height: 285px;
   }
}
.roles_data {
    width: 90%;
    margin: auto;
}
.roles_data p{
    color: #FFF;
    text-align:left;
    font-size: 23px;
    font-style: normal;
    line-height: 150%;
}
.roles_data_bold{
    font-size: 22px;
    font-weight: bold;

}
.roles_circle{
    width: 600px;
    height: 600px;
    position: absolute;
    border-radius: 50%;
    background: rgba(70, 128, 255, 0.10);
    left: -22%;
    margin-top: -20%;
}
.how_it_works{
    color: var(--website-color, #4680FF);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 87.8%;
}
.working_container_1{
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}
.working_content{
    text-align:left;
}
.working_content_box {
    display: flex;
    width: 75%;
    justify-content: space-around;
    align-items: center;
}
.working_content_head{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 87.8%; 
}
.working_content_text{
    color: rgba(0, 0, 0, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
}
.roles_circle_right{
    border-radius: 50%;
    background: rgba(70, 128, 255, 0.10);
    width: 752px;
    height: 752px;
    right: -28%;
    position: absolute;
    flex-shrink: 0;
    margin-top: 56%;
}
.contact_us_container{
    width:85%;
    margin: auto;
    padding-top:75px;
}
.contact_us_bottom{
    display:flex;
    align-items:center;
    p{
        color: #222;
        margin:0;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        padding-right:10px;
    }
    img{
        width:50px;
        height: 50px;
    }
}
.contact_us_text{
    margin-top:5px;
    text-align:left;
    color: var(--greyish-blue-tint, #535674);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}
.next_step_container{
    border-radius: 15px;
    background: #424345;
    text-align:left;
    padding:25px;
    display:flex;
    align-items:center;
    margin-bottom:25px;
}
.next_step_col{
    width:50%;
}
.next_step_head{
    color: #FFF;
    margin:0;
    font-size: 31px;
}
.next_step_text{
    color: #FFF;
    font-size: 18px;
    font-weight: 400;
}
.inputWithButton_col {
  position: relative;
  width : 50%;
}
.inputWithButton input{
    width: 70%;
    height: 35px;
    border-radius:8px;
    border:none;
    font-size: 18px;
    color: ##4b4646;
    padding-left:10px;
    padding-right: 60px;
    
}
.next_step_input::placeholder{
    color: #898989;
    padding-left:10px;
    font-size: 18px;
}
.next_step_btn {
    position: absolute;
    right: 10px;
    top: 8px;
    height:40px;
    color: #FFF;
    font-size: 18px;
    font-weight: 400;
    width:110px;
    cursor:pointer;
    border-radius: 8px;
    border:none;
    background: rgba(27, 27, 27, 0.90);
}
.inputWithButton {
    display: flex;
    align-items: stretch;
}

.inputContainer {
    flex: 1;
    display: flex;
    align-items: stretch;
}

.next_step_input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.data_report_img{
    max-width:100%;
}
.works_container{
    position:relative;
    overflow:hidden;
}

@media (max-width: 768px) {  
    .home_contents_sub_text{
        width: 80%;
    }
    .feature_container{    
        width:90%;
        display: flex;
        flex-wrap:wrap;
        gap:1rem;
    }
    .feature_card{
        flex: 1 0 180px;      
    }
    .more_btn_container {
        width: 75%;        
    }
    .roles_data {
        width: 100%;
    }
    .dotted_lines{
        width:40%;
    }
    .roles_circle{       
        width: 200px;
        height: 200px;
        position: absolute;
        border-radius: 50%;
        background: rgba(70, 128, 255, 0.10);
        left: -14%;
        margin-top: -10%;

    }
    .next_step_container{
        display:block;
    }
    .next_step_col{
        width:100%;
    }
    .inputWithButton input{
        width: 90%;        
    }
    .inputWithButton_col {
        width : 100%;
    }
    .working_content_box {
        width:85%;
        display: flex;
        flex-direction: column;
    }
    .master_data_img img, {
        height: unset;
    }
    .roles_data_img {
        img{
            height: unset;
        }
        width: 98%;
    }
    .master_container {
        flex-direction: column;
    }
    .roles_circle_right {
        display: none;
    }
}    

@media (min-width: 769px) and (max-width: 1024px) {
    .feature_container{    
        width:65%;
        display: grid;
        grid-template-columns: auto auto auto;  
        column-gap:1rem;  
        margin:auto;
    }
    .roles_circle{       
        width: 400px;
        height: 400px;
        position: absolute;
        border-radius: 50%;
        background: rgba(70, 128, 255, 0.10);
        left: -28%;
        margin-top: -25%;
    }
    .dotted_lines{
        width:48%;
    }
  
}
@media (min-width: 1024px){
   .working_content_box{
        margin-bottom: -30px;
        margin-top: -30px;
   }
  
}
`;
