import styled from "styled-components";

const RegisterWrapper = styled.div`
.header_image img{
    /* object-fit: contain; */
    width:100%;
    max-height: 300px;
    margin-bottom:20px;
}
.register_container{
    width: 100%;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.register_title{
    margin: 0;
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;
    padding-bottom: 30px;
}
.register_from_container{
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 80px;
    border: 1px solid var(--greyish-blue-tint, #535674);
    background: #FFF;
    padding: 2rem;
    column-gap:2rem;
}
.register_section{
    width: 435px;
    height: 660px;
    border-radius: 20px;
    background: rgba(43, 50, 178, 0.80);
    flex-shrink: 0;
    padding:1rem;
    text-align: left;
    position: relative;
    overflow: hidden; 
}
.inside_register{
    width:80%;
    margin:auto;
}
.form_group{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    margin-bottom: 2rem;
}
.form_group_full{
    width: 100% !important;
}
.placeholder_styles::placeholder{
    color: rgba(83, 86, 116, 0.50);
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    letter-spacing: 0.36px;
}
.reg_form_fields_container{
    margin-top: 2.5rem;
    width: 100%;
}
.reg_form_fields_section{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.form_fields_wrapper{
    width: 100%;
    display: flex;
    gap: 3rem;
}
.form_label{
    color: #000;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    letter-spacing: 0.36px;
}
.form_input_field{
    width: 100%;
    height: 20px;
    border: 0px;
    outline: none;
    border-bottom: 1px solid rgba(83, 86, 116, 0.50);
}
.reg_submit_btn{
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    .submit_btn{
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        line-height: 140%;
        padding: 10px 20px;
        border: none;
        outline: none;
        border-radius: 30px;
        background: rgba(43, 50, 178, 0.80);
    }
    .cancel_btn{
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        border: none;
        outline: none;
        background: transparent;
    }
}
.contact_info_head{
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1.48px;
}
.contact_text{
    color: rgba(255, 255, 255, 0.70);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
    letter-spacing: 0.32px;
}
.circle_div{
    width:30px;
    height:30px;
    border-radius: 50%;
    background: #fff;
    display:flex;
    justify-content: center;
    align-items: center;
}
.mail_row{   
    display: flex;
    align-items:center;
}
.mail_text{
    color: #FFF;
    font-size: 18px;
    font-weight: 400;
    line-height: 160%; 
    letter-spacing: 0.36px;
    margin-left: 1rem;
}
.circle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150px; 
    height: 150px; 
    background-color: #45499c;
    border-radius: 100% 0 0 0;     
}
.outer_circle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 250px; 
    height: 250px; 
    background-color: #4d52ae;
    border-radius: 100% 0 0 0;     
}

@media (min-width: 912px) and (max-width: 1168px){
    .form_fields_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .form_group{
        width: 100%;
        margin-bottom: 1rem;
        gap: 0.8rem;
    }
}
@media (min-width: 592px) and (max-width: 911px){
    .register_from_container{
        align-items: center;
        flex-direction: column;
        border-radius: 20px;
        padding: 1.4rem;
    }
}
@media (min-width: 320px) and (max-width: 591px){
    .register_from_container{
        width: 90%;
        align-items: center;
        flex-direction: column;
        border-radius: 16px;
        padding: 1rem;
    }
    .register_section{
        width: 100%;
        height: 400px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .form_fields_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .form_group{
        width: 100%;
        margin-bottom: 1rem;
        gap: 0.8rem;
    }
}
`;
export default RegisterWrapper;