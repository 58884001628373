import styled from "styled-components";

const FooterWrapper = styled.div`
    .footer_container{
        width: 100%;
        height: 397px;
        flex-shrink: 0;
        background: rgba(10, 20, 47, 0.85);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer_logo{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer_lists_section{
        width: 30%;
        margin-top: 40px;
    }
    .footer_lists{
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        list-style: none;
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 0px 5px;
    }
    .social_media_section{
        display: flex;
        justify-content: space-between;
    }
    .social_media_icon_box{
        width: 65px;
        height: 65px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #f2f2f221;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            font-size: 35px !important;
            fill: white;
        }
    }
    .footer_copyrights_Section{
        width: 35%;
    }
    .footer_copyrights_lists{
        display: flex;
        justify-content: space-between;
        list-style: none;
        color: rgba(255, 255, 255, 0.50);
        /* font-family: Inter; */
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 0;
        margin-top: 2rem;
    }
    .footer_menus{
        cursor:pointer
    }

    @media (min-width: 992px) and (max-width: 1024px){
        .footer_lists_section{
            width: 60%;
            align-items: center;
        }
        .footer_copyrights_Section{
            width: 65%;
        }
    }
    @media (min-width: 768px) and (max-width: 991px){
        .footer_lists_section{
            width: 60%;
            align-items: center;
        }
        .footer_copyrights_Section{
            width: 65%;
        }
    }
    @media (min-width: 481px) and (max-width: 767px){
        .footer_lists_section{
            width: 60%;
            align-items: center;
        }
        .footer_copyrights_Section{
            width: 70%;
        }
    }
    @media (min-width: 320px) and (max-width: 480px){
        .footer_lists_section{
            width: 92%;
            align-items: center;
        }
        .footer_copyrights_Section{
            width: 98%;
            text-align: center;
        }
    }
`

export default FooterWrapper;